// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
//@import "~@coreui/coreui-pro/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

:root {
  --font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}
